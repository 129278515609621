import React from "react"
import Layout from "../../components/layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Seo from "../../utils/seo"
import { useActiveMenu } from "react-active-menu"

import img1 from "../../images/bunfestival/aruniverse/1.png"
import img2 from "../../images/bunfestival/aruniverse/2.png"
import img3 from "../../images/bunfestival/aruniverse/3.png"
import img4 from "../../images/bunfestival/aruniverse/4.png"
// import img5 from "../../images/bunfestival/aruniverse/5.png"
// import img6 from "../../images/bunfestival/aruniverse/6.png"
import img7 from "../../images/bunfestival/aruniverse/7.png"
import img8 from "../../images/bunfestival/aruniverse/8.png"
import img9 from "../../images/bunfestival/aruniverse/9.png"

import filter1 from "../../images/bunfestival/filters/1.png"
import filter2 from "../../images/bunfestival/filters/2.png"
import filter3 from "../../images/bunfestival/filters/3.png"
import filter4 from "../../images/bunfestival/filters/4.png"
import filter5 from "../../images/bunfestival/filters/5.png"
import filter6 from "../../images/bunfestival/filters/6.png"
import filter7 from "../../images/bunfestival/filters/7.png"
import filter8 from "../../images/bunfestival/filters/8.png"
import filter9 from "../../images/bunfestival/filters/9.png"
import filter10 from "../../images/bunfestival/filters/10.png"
import newsImage from "../../images/bunfestival/mediaNews.png"

import GeneralMap from "../../utils/GeneralMap"

export default function ARUniverse() {
  const { registerSection, registerTrigger } = useActiveMenu({
    offset: 120,
    smooth: true,
  })
  return (
    <Layout>
      <Container>
        <Row>
          <Col md={2} className="p-0">
            <div className="sticky-menu">
              <button
                className="sticky-menu-text"
                ref={registerTrigger("section-1")}
                type="button"
              >
                簡介
              </button>
              <button
                className="sticky-menu-text"
                ref={registerTrigger("section-2")}
                type="button"
              >
                AR多重宇宙
              </button>
              <button
                className="sticky-menu-text"
                ref={registerTrigger("section-3")}
                type="button"
              >
                換領2部曲
              </button>
              <button
                className="sticky-menu-text"
                ref={registerTrigger("section-4")}
                type="button"
              >
                AR導賞團
              </button>
              <button
                className="sticky-menu-text"
                ref={registerTrigger("section-5")}
                type="button"
              >
                AR文化講座
              </button>
              <button
                className="sticky-menu-text"
                ref={registerTrigger("section-6")}
                type="button"
              >
                文物鑒賞工作坊
              </button>
              <button
                className="sticky-menu-text"
                ref={registerTrigger("section-7")}
                type="button"
              >
                AR街頭音樂會
              </button>
              <button
                className="sticky-menu-text"
                ref={registerTrigger("section-8")}
                type="button"
              >
                嗚謝
              </button>
              <button
                className="sticky-menu-text"
                ref={registerTrigger("section-9")}
                type="button"
              >
                聯絡
              </button>
            </div>
          </Col>
          <Col md={10}>
            <h6>AR打卡點 + 文化講座 + 工作坊 + 音樂會 + 導賞團</h6>
            <h2 className="about_slogan">長洲太平清醮．AR多重宇宙</h2>

            <img
              src={img1}
              alt="長洲太平清醮．AR多重宇宙"
              className="about_M2gether_IMG mt-3"
            />

            <section className="pb-3" ref={registerSection("section-1")}>
              <h4 className="pb-2">活動簡介</h4>
              <p>
                相隔三年的長洲太平清醮將於5月23日至27日盛大舉行！除了經典的搶包山和飄色活動，今年還有一連串生動有趣的特備節目，適合一眾同樂，潮玩長洲AR多重宇宙！
              </p>
              <p>
                从二創作團隊致力推廣本土傳統文化活動，今年於長洲太平清醮期間策動一場揉合創意科技與文化傳統（Art-Tech-Culture）的文化推廣活動，讓遊客進一步了解傳統節日一些細節及背後意義。當中最有趣的莫過於「潮玩AR」，利用AR擴增實景互動遊戲/濾鏡的形式，於不同景點（例如：北帝廟、北社街街坊會、洪聖廟、關公忠義亭等）設立互動打卡點，在遊走長洲的同時，可以透過手機召喚虛擬包山、Ｑ版北帝爺甚至架駛張保仔海盜船，務求讓市民以全新沉浸式體驗去感受長洲特色，以另類方式保育長洲文化。
              </p>
              <p>
                在整個的太平清醮活動中，透過一系列實體活動，包括講座、工作坊、導賞團、街頭音樂會及打卡換禮物活動，以創新科技探索長洲文化的另一面。
              </p>
            </section>

            <section className="pb-3" ref={registerSection("section-2")}>
              <img
                src={img2}
                alt="長洲太平清醮．AR多重宇宙"
                className="about_M2gether_IMG"
              />

              <h4 className="pb-2">AR多重宇宙．打卡換禮品活動</h4>
              <p>
                未出發先興奮，在排隊上船其間也有驚喜。从二團隊會於中環碼頭及長洲碼頭免費派發地圖紙扇，只要掃扇上的QR
                CODE，便可以即時體驗多款AR遊戲／濾鏡，同時亦可在連結中了解長洲太平清醮活動的相關資訊。透過遊戲鼓勵旅客遊走長洲不同特色景點，集齊AR打卡照片，還可免費獲得由道地麥茶，嘉士伯，七喜及無双贊助的禮品。
              </p>
              <Row>
                <Col className="text-center" xs={6} md={3}>
                  <a
                    href="https://www.instagram.com/ar/1220350568625458/"
                    target="blank"
                  >
                    <img
                      src={filter8}
                      alt="長洲太平清醮．快問快答"
                      className="about_M2gether_IMG"
                    />
                    <h6>快問快答．睇你了解幾多</h6>
                    <p>點擊開啟</p> <br></br>
                  </a>
                </Col>
                <Col className="text-center" xs={6} md={3}>
                  <a
                    href="https://www.instagram.com/ar/3367690490157359/"
                    target="blank"
                  >
                    <img
                      src={filter7}
                      alt="長洲太平清醮．遙控海盜船"
                      className="about_M2gether_IMG"
                    />
                    <h6>張保仔．遙控海盜船</h6>
                    <p>點擊開啟</p> <br></br>
                  </a>
                </Col>
                <Col className="text-center" xs={6} md={3}>
                  <a
                    href="https://www.instagram.com/ar/597343169030246/"
                    target="blank"
                  >
                    <img
                      src={filter4}
                      alt="長洲太平清醮．震撼鬼王大士王"
                      className="about_M2gether_IMG"
                    />
                    <h6>震撼鬼王大士王</h6>
                    <p>點擊開啟</p> <br></br>
                  </a>
                </Col>
                <Col className="text-center" xs={6} md={3}>
                  <a
                    href="https://www.instagram.com/ar/6179894352100469/"
                    target="blank"
                  >
                    <img
                      src={filter5}
                      alt="長洲太平清醮．神明保佑北帝爺"
                      className="about_M2gether_IMG"
                    />
                    <h6>神明保佑北帝爺</h6>
                    <p>點擊開啟</p> <br></br>
                  </a>
                </Col>
                <Col className="text-center" xs={6} md={3}>
                  <a
                    href="https://www.instagram.com/ar/567262278854838/"
                    target="blank"
                  >
                    <img
                      src={filter3}
                      alt="長洲太平清醮．長洲特色搶包山"
                      className="about_M2gether_IMG"
                    />
                    <h6>長洲特色搶包山</h6>
                    <p>點擊開啟</p> <br></br>
                  </a>
                </Col>
                <Col className="text-center" xs={6} md={3}>
                  <a
                    href="https://www.instagram.com/ar/634795424814465/"
                    target="blank"
                  >
                    <img
                      src={filter6}
                      alt="長洲太平清醮．平安包．召喚"
                      className="about_M2gether_IMG"
                    />
                    <h6>
                      平安包．召喚<br></br>（掃描扇上的平安包圖案）
                    </h6>
                    <p>點擊開啟</p> <br></br>
                  </a>
                </Col>
                <Col className="text-center" xs={6} md={3}>
                  <a
                    href="https://www.instagram.com/ar/139203835812201/"
                    target="blank"
                  >
                    <img
                      src={filter1}
                      alt="長洲太平清醮．傳統閃動勁花牌"
                      className="about_M2gether_IMG"
                    />
                    <h6>
                      傳統閃動勁花牌<br></br>
                      （掃描長洲ARENA[7-11旁]限定花牌。禮品換領點）
                    </h6>
                    <p>點擊開啟</p> <br></br>
                  </a>
                </Col>
                <Col className="text-center" xs={6} md={3}>
                  <a
                    href="https://www.instagram.com/ar/633042525338409/"
                    target="blank"
                  >
                    <img
                      src={filter2}
                      alt="長洲太平清醮．一齊黎舞獅頭啦"
                      className="about_M2gether_IMG"
                    />
                    <h6>自拍系列：一齊黎舞獅頭啦</h6>
                    <p>點擊開啟</p> <br></br>
                  </a>
                </Col>
                <Col className="text-center" xs={6} md={3}>
                  <a
                    href="https://www.instagram.com/ar/5726820800756993/"
                    target="blank"
                  >
                    <img
                      src={filter9}
                      alt="長洲太平清醮．試試花旦造型"
                      className="about_M2gether_IMG"
                    />
                    <h6>自拍系列：試試花旦造型</h6>
                    <p>點擊開啟</p> <br></br>
                  </a>
                </Col>
                <Col className="text-center" xs={6} md={3}>
                  <a
                    href="https://www.instagram.com/ar/237109922269938/"
                    target="blank"
                  >
                    <img
                      src={filter10}
                      alt="長洲太平清醮．關帝亭浪漫櫻花"
                      className="about_M2gether_IMG"
                    />
                    <h6>自拍系列：關帝亭浪漫櫻花</h6>
                    <p>點擊開啟</p> <br></br>
                  </a>
                </Col>
              </Row>
              <hr></hr>
            </section>

            <section className="pb-3" ref={registerSection("section-3")}>
              <h4 className="pb-2">換領2部曲</h4>

              <Row>
                <Col xs={12} md={6}>
                  活動網頁：
                  <a
                    className="temple-detail-contact"
                    target="blank"
                    href="https://linktr.ee/m2gether"
                  >
                    連結
                  </a>
                  <p>
                    步驟1：Follow我地
                    <a
                      className="temple-detail-contact"
                      target="blank"
                      href="https://www.facebook.com/m2gether.hk"
                    >
                      Facebook
                    </a>
                    &
                    <a
                      className="temple-detail-contact"
                      target="blank"
                      href="https://www.instagram.com/m2gether.hk/"
                    >
                      Instagram
                    </a>
                    專頁
                    <br></br>
                    步驟2：完成任何2個AR打卡Filter 分享並Tag<br></br>
                    @m2gether.hk #m2gether #長洲太平清醮{" "}
                  </p>
                  <p>
                    完成後立即到<b>長洲ARENA</b>換領禮物<br></br>
                    長洲新興海傍街35號地舖 [長洲碼頭 7-11便利店旁]
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <img
                    src={img4}
                    alt="長洲太平清醮 換領2部曲 禮物"
                    className="about_M2gether_IMG"
                  />
                </Col>
              </Row>
              <img
                src={img3}
                alt="長洲太平清醮 換領2部曲 "
                className="about_M2gether_IMG"
              />
              <hr></hr>
              <p>
                數量有限，換完即止。換領時間：5月24一26日下午1:00一4:00<br></br>
                詳情請向店員查詢。商戶保留權利隨時暫停、更改或終止優惠，毋須另行通知。
              </p>

              <hr></hr>
            </section>

            <section className="pb-3" ref={registerSection("section-4")}>
              <img
                src={img9}
                alt="長洲太平清醮 AR多重宇宙．導賞團"
                className="about_M2gether_IMG"
              />
              <h4 className="pb-2">AR多重宇宙．導賞團</h4>
              <p>
                我們安排由持牌導遊及長洲學生領團，在這90分鐘的導賞團，帶領公眾窺探創新與傳統文化的交叉領域，邊遊走於長洲，邊利用AR另類打卡體驗及了解傳統節日一些細節及背後意義，增進遊客對節日的認知的同時為學生提升社會經驗。
              </p>
            </section>

            <section className="pb-3" ref={registerSection("section-5")}>
              <h4 className="pb-2">長洲太平清醮．AR文化講座</h4>
              <p>
                是次講座請來資深民俗掌故研究者周樹佳先生於長洲官立中學講解長洲太平清醮的由來，介紹太平清醮一些特別儀式及細節，加入了AR體驗，以有趣角度令本地青少年進一步增強對本土傳統節日及中國文化的認識。
              </p>
            </section>

            <section className="pb-3" ref={registerSection("section-6")}>
              <h4 className="pb-2">古董說中國故事．文物鑒賞工作坊</h4>
              <p>
                劉毅先生帶著珍貴古董走進長洲官立中學，用古董說中國故事。交流活動讓學生加深認識中國近代百年藝術文化與歷史息息相關，源遠流長，令學生們清楚明白，每件事與物背後都有它的故事，令學生們知道傳承的重要性。
              </p>
            </section>

            <section className="pb-3" ref={registerSection("section-7")}>
              <img
                src={img7}
                alt="長洲太平清醮．AR街頭音樂會"
                className="about_M2gether_IMG"
              />
              <Row>
                <Col xs={12} md={6}>
                  <h4 className="pb-2">AR街頭音樂會</h4>
                  <p>
                    我們亦安排本地年青樂隊「九九零零」及「Alice's
                    Waffle」，將街頭音樂融入傳統文化節目中，利用現場環境及AR濾鏡，增加觀眾參與度同時更有趣地融合中西文化，促進文化多樣性及創造不同的可能性。
                  </p>
                  <h6>活動資料</h6>
                  <p>本地年青樂隊「九九零零」及「Alice's Waffle」</p>
                  <p>
                    位置：長洲中興街洪聖廟外空地<br></br>時間：5月26日 11:30am -
                    1:00pm
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <GeneralMap
                    height={300}
                    zoom={19}
                    lat={22.205703}
                    long={114.0284823}
                  ></GeneralMap>
                </Col>
              </Row>
              <br></br>
              <hr></hr>
            </section>

            <section className="pb-3" ref={registerSection("section-8")}>
              <h4 className="pb-2">嗚謝</h4>
              <p>
                是此項目得到不同機構及商界支持，目標旨在共同維護、推動香港獨有的文化傳統，同時亦可讓贊助及支持機構向公眾展示落實支持傳統文化的決心。我們相信隨著AR技術的不斷發展，利用開拓應用場景去推廣傳統文化，將會帶來了更多機會和可能性，務求能從《守正創新》的方向得以傳承下去。
              </p>
              <img
                src={img8}
                alt="長洲太平清醮．AR多重宇宙 贊助"
                className="about_M2gether_IMG mt-2 pt-3"
              />
              <hr></hr>
            </section>

            <section className="pb-3" ref={registerSection("section-9")}>
              <h4 className="pb-2">報導</h4>
              <p>26.05.2023 明周文化報導</p>
              <img
                src={newsImage}
                alt="長洲居民與設計師　創太平清醮AR遊戲與濾鏡　創意吸引年輕人關注"
                className="about_M2gether_IMG mt-2 pt-3"
              />
              <br /> <br />
              <h4 className="pb-2">聯絡</h4>
              <p>
                「从二」團隊是一群對傳統節日、習俗及文化有著濃厚興趣的人組成，成員包括學者、設計師、藝術家、軟件工程師等。我們相信傳統文化是一個地方的根基，因此我們致力於透過科技的應用，為傳統文化注入新的活力，讓人們可以更加互動地體驗，並且了解傳統文化的價值和意義。我們相信透過凝聚社群的力量，提升社群的認同感，令傳統文化可以得到更好的保護和傳承，也可以更好地融入現代社會的發展中。
              </p>
              <p>
                若您對从二有任何查詢、媒體報導、工作職缺或業務合作的詢問，歡迎與我們聯繫！您可以透過以下方式聯絡我們：
              </p>
              <p>電郵：hi@m2gether.com</p>
              <p>
                香港灣仔堅拿道西10號冠景樓3樓A室<br></br>
                Unit A, 3/F, Top View Mansion, 10 Canal Road West, Hong Kong
                <br></br>
              </p>
            </section>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="長洲太平清醮 AR多重宇宙"
    description="从二創作團隊致力推廣香港傳統文化活動，今年我們在長洲太平清醮混合創新科技與傳統文化(Art-Tech-Culture)，利用AR擴增實景互動遊戲/濾鏡的形式，於長洲不同的景點（北帝廟、北社街街坊會、洪聖廟、關公忠義亭等）設立互動打卡點，在遊走長洲的同時，可以利用手機召喚虛擬包山、Ｑ版北帝爺、大士王及張保仔海盜船等，目的讓更多朋友可以以全新沉浸式體驗去感受長洲及太平清醮的傳統文化，以另一種方式保育長洲文化，進一步了解傳統節日一些細節及背後意義。"
    siteUrl="http://m2gether.com/bunfestival"
  />
)
