import React from 'react';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

function GeneralMap(data) {

  const center = ({ lat: data.lat, lng: data.long })
  const zoom = data.zoom
  const height = data.height

  const { isLoaded } = useLoadScript({ googleMapsApiKey: 'AIzaSyA81kiCX5vFWRdQr56Y5mql_3fCYKZhNAc', });
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <GoogleMap zoom={zoom} center={center} mapContainerStyle={{
      height: `${height}px`,
      borderRadius: '10px',
    }}>
      <Marker position={center} />
    </GoogleMap>
  )
}
export default GeneralMap